import { Grid, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useEffect } from 'react';
import { useDictionary, useForm } from '../hooks';
import moment from 'moment';
import { isDateGreater, isRequired } from '../hooks/useForm';
import { BaseNoLoaderService } from '../api';
/* import { subDays } from 'date-fns'; */

const AddDailyActivities = ({ formRef }) => {

    const DATE_FORMAT = "YYYY-MM-DD";
    const TIME_FORMAT = "HH:mm";
    const now = new Date();
    const INITIAL_STATE = {
        note: '',
        dateto: moment().subtract(1, 'days').format(DATE_FORMAT),
        datefrom: moment().subtract(1, 'days').format(DATE_FORMAT),
        days: 1
    };
    const dictionary = useDictionary();
    const validations = [
        ({ datefrom, dateto }) => isDateGreater(datefrom, dateto) || { datefrom: dictionary["businesstrips"]["form"]["errors"]["fromGreaterTo"], dateto: dictionary["businesstrips"]["form"]["errors"]["fromGreaterTo"] },
        ({ datefrom }) => isRequired(datefrom) || { datefrom: dictionary["businesstrips"]["form"]["errors"]["fromRequired"] },
        ({ dateto }) => isRequired(dateto) || { dateto: dictionary["businesstrips"]["form"]["errors"]["toRequired"] }
    ];

    useEffect(() => {
        formRef.note = INITIAL_STATE.note;
        formRef.dateto = INITIAL_STATE.dateto;
        formRef.datefrom = INITIAL_STATE.datefrom;
        formRef.days = INITIAL_STATE.days;
    }, []);

    const { values, isValid, errors, touched, changeHandler } = useForm(INITIAL_STATE, validations);

    formRef.isValid = isValid;

    const onDateChange = async (newValue, property) => {
        formRef[property] = newValue?.format(DATE_FORMAT);
        // FIX HOLIDAY DAYS CALCULATION
        const result = await BaseNoLoaderService.get(`profiles/workingdays/${formRef.datefrom}/${formRef.dateto}`)
        formRef.days = result.data.total;
        //formRef.days = dateDiff(formRef.dateto, formRef.datefrom) + 1;
        changeHandler(property, newValue); /* questa la faccio qui così posso vedere formRef.days aggiornato sulla UI, se lo facessi prima vedrei il valore di formRef.days precedente */
    };

   // Calcola la data di ieri utilizzando Moment.js
  const yesterday = moment().subtract(1, 'days');

    return (
        <Grid container spacing={2} sx={{ mt: theme => theme.spacing(1) }}>
            <Grid item xs={12} md={12} lg={12}>
                <DatePicker
                    inputFormat='DD/MM/YYYY'
                    maxDate={yesterday}
                    label={dictionary["businesstrips"]["form"]["from"]}
                    value={values.datefrom || ''}
                    onChange={newValue /* it is moment formatted */ => {
                        onDateChange(newValue, "datefrom");
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            size="small"
                            error={Boolean(touched.datefrom && errors.datefrom)}
                            helperText={touched.datefrom && errors.datefrom}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <DatePicker
                    inputFormat='DD/MM/YYYY'
                    maxDate={yesterday}
                    label={dictionary["businesstrips"]["form"]["to"]}
                    value={values.dateto || ''}
                    onChange={newValue /* it is moment formatted */ => {
                        onDateChange(newValue, "dateto");
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            size="small"
                            error={Boolean(touched.dateto && errors.dateto)}
                            helperText={touched.dateto && errors.dateto}
                        />
                    )}
                />
            </Grid>
            { formRef.days > 0 && (
                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="body2">
                        {dictionary["businesstrips"]["form"]["totDays"]}{": "}{formRef.days}
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12} md={12} lg={12}>
                <TextField
                    size="small"
                    minRows={2}
                    maxRows={7}
                    fullWidth
                    multiline
                    label={dictionary["note"]}
                    value={values.note}
                    onChange={({ target }) => {
                        changeHandler("note", target.value);
                        formRef.note = target.value;
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default AddDailyActivities;