import createDataContext from "./createContext";
import BaseRequest from "../api/Base";
import { AnomaliesService, UsersService } from "../api";
import { generateUOW, getJustificationEventIdByTypeId } from "../utilities";

const SET_ANOMALIES = "set_anomalies";
const SET_USERS_ANOMALIES = "set_users_anomalies";
const SET_BANKHOURS = "set_bankhours";

const INITIAL_STATE = {
  anomalies: [],
  usersAnomalies: [],
  bankHours: {}
};


const AnomaliesReducer = (state, action) => {
  switch (action.type) {
    case SET_ANOMALIES: {
      const { anomalies } = action.payload;
      return {
        ...state,
        anomalies
      };
    }
    case SET_BANKHOURS: {
      const { bankHours } = action.payload;
      return {
        ...state,
        bankHours
      };
    }
    case SET_USERS_ANOMALIES: {
      return { ...state, usersAnomalies: [...action.payload] };
    }
    default: {
      return state;
    }
  }
};

const setAnomalies =
  dispatch => async (userid, {type, datefrom, dateto, status}) => {
    datefrom = datefrom ? datefrom : "ALL";
    dateto = dateto ? dateto : "ALL";
    const { data: { anomalies } } = await AnomaliesService.get(
      `${userid}/${status}/${datefrom}/${dateto}/${type}`
    );
    dispatch({ type: SET_ANOMALIES, payload: { anomalies } });
  };

const setDashboardAnomalies = dispatch => async userid => {
  const { data: { anomalies } } = await AnomaliesService.get(`${userid}`);
  dispatch({ type: SET_ANOMALIES, payload: { anomalies } });
};

const setBankHours = (dispatch) => async (userid, year = new Date().getFullYear(), month = new Date().getMonth()+1) => {
  const { data: { data: bankHours } } = await UsersService.get(`${userid}/bankhours/balance`, {params:{year, month}});
  dispatch({ type: SET_BANKHOURS, payload: { bankHours } });
};

const setUsersAnomalies = dispatch => async () => {
  const response = await BaseRequest.get(
    `events/approver/NOTJUSTIFIED/ALL/ALL`
  );
  dispatch({ type: SET_USERS_ANOMALIES, payload: response.data.events });
}
const justificationAnomalies = dispatch => async (anomaly, formRef, searchForm, justificationsType) => {
  const originalJustifications = anomaly.justifications;
  const { userid } = anomaly;
  if (!formRef.justifications) {
    return;
  }
  const newJustifications = formRef.justifications.map(justification => ({
    // ...justification.commission,
    // ...justification,
    minutescount: justification.minutescount,
    eventid: anomaly.id,
    userid,
    costcenterid: justification.costcenter?.id,
    // typeid: getJustificationEventIdByTypeId(justificationsType, anomaly.typeid),
    typeid: justification.motivation.id,
    motivation: undefined,
    notes: justification.notes,
    _uid: justification._uid
  }));
  const justifications = generateUOW(originalJustifications, newJustifications, "_uid");
  
  await BaseRequest.post(`justifications/${userid}`, { justifications, userid });
  setAnomalies(dispatch)(userid, searchForm);
};

export const { Provider, Context } = createDataContext(
  AnomaliesReducer,
  {
    setAnomalies,
    setDashboardAnomalies,
    setUsersAnomalies,
    justificationAnomalies,
    setBankHours
  }, // actions
  INITIAL_STATE // initial state
);
