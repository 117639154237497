import {InfoOutlined, Savings} from "@mui/icons-material";
import {Card, IconButton, Stack} from "@mui/material";
import React, {useContext, useEffect} from "react";
import {Link} from "react-router-dom";
import {AnomaliesContext} from "../contexts";
import {useDictionary, useUser} from "../hooks";
import HeaderCollapse from "./HeaderCollapse";
import BankHoursTables from "./BankHoursTables";
import {ROLES, userHasOneOfRoles} from "../utilities";

const BankHoursPanel = () => {
  const {
    state: {bankHours},
    setBankHours,
  } = useContext(AnomaliesContext);

  const {user} = useUser();
  const dictionary = useDictionary();

  useEffect(() => {
    if (user && Object.keys(bankHours).length === 0) {
      setBankHours(user.id);
    }
  }, [user, bankHours]);

  return Object.keys(bankHours).length > 0 && userHasOneOfRoles([ROLES.BANK_HOURS], user) ? (
    <Card>
      <HeaderCollapse
        color={"info"}
        title={"Banca Ore"}
        notificationCount={bankHours ? (Object.keys(bankHours).length === 0 ? 0 : 1) : 0}
        Icon={Savings}
        actions={
          <Stack direction="row" justifyContent="center" alignItems="center">
            <IconButton
              sx={{color: (theme) => theme.palette.common.white}}
              size="small"
              component={Link}
              to="/approvers">
              <InfoOutlined fontSize="small" />
            </IconButton>
          </Stack>
        }
      />
      {bankHours !== undefined ? <BankHoursTables searchedAnomalies={[bankHours]} mini /> : <></>}
    </Card>
  ): <></>;
};

export default BankHoursPanel;
